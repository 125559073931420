export enum LEAD_POLICY {
  ACTIVE = 'ACTIVE',
  MINIMUM_PAYMENT = 'MINIMUM_PAYMENT',
  STOP_PAYMENT = 'STOP_PAYMENT',
  CANCEL = 'CANCEL'
}

export enum LEAD_TYPE {
  PROSPECT = 'PROSPECT',
  CUSTOMER = 'CUSTOMER',
  HIDDEN = 'HIDDEN'
}

export enum LEAD_LOG_TYPE {
  CONTACT = 'CONTACT',
  HOME = 'HOME',
  WORK = 'WORK'
}

export enum LEAD_BULK_ACTION {
  CHANGE_OWNER = 'CHANGE_OWNER',
  CHANGE_AGENT = 'CHANGE_AGENT',
  CHANGE_CSR = 'CHANGE_CSR',
  HIDE = 'HIDE',
  EXPORT = 'EXPORT'
}

export enum LeadColEnum {
  OWNER = 'owner',
  LEAD = 'lead',
  LEAD_ID = 'leadId',
  LEAD_TYPE = 'leadType',
  LEAD_STAGE = 'leadStage',
  STATUS = 'leadStatus',
  NAME = 'contactName',
  CELLPHONE = 'contactCellPhone',
  WORK_PHONE = 'contactWorkPhone',
  STATE = 'state',
  SOURCE = 'leadSource',
  AGENT = 'agent',
  EMAIL = 'contactEmail',
  GENDER = 'gender',
  DATE_OF_BIRTH = 'dateOfBirth',
  MARITAL_STATUS = 'maritalStatus',
  POLICY_STATUS = 'policyStatus',
  LEAD_TAG = 'tags',
  CITY = 'city',
  USA_CITIZEN = 'isUSACitizen',
  GREEN_CARD = 'greenCardNumber',
  DRIVER_LICENSE = 'driverLicense',
  SSN_TAX_ID = 'taxId',
  CSR = 'csr',
  DATE_ADDED = 'createdAt',
  CREATED_BY = 'createdBy',
  SUB_SOURCE = 'Subsource'
}

export enum LeadExcelDataKey {
  ADDRESS = 'ADDRESS',
  AGENT = 'AGENT_(*)',
  AVATAR = 'AVATAR',
  CELLPHONE = 'CELLPHONE',
  CITY = 'CITY',
  CONTACT_CELLPHONE = 'CONTACT CELLPHONE_(*)',
  CONTACT_EMAIL = 'CONTACT EMAIL',
  CONTACT_NAME = 'CONTACT NAME_(*)',
  CONTACT_WORK_PHONE = 'CONTACT WORK PHONE',
  CREATED_BY = 'CREATED BY',
  CSR = 'CSR',
  DATE_OF_BIRTH = 'DATE OF BIRTH',
  DRIVER_LICENSE = 'DRIVER LICENSE',
  EMAIL = 'EMAIL',
  FULL_NAME = 'FULL NAME_(*)',
  GENDER = 'GENDER',
  GREEN_CARD_NUMBER = 'GREEN CARD NUMBER',
  LEAD_STAGE = 'LEAD STAGE_(*)',
  LEAD_STATUS = 'LEAD STATUS_(*)',
  LEAD_TAG = 'LEAD TAG',
  LEAD_TYPE = 'LEAD TYPE',
  MAILING_ADDRESS = 'MAILING ADDRESS',
  MAILING_CITY = 'MAILING CITY',
  MAILING_STATE_PROVINCE = 'MAILING STATE/PROVINCE',
  MAILING_ZIP_CODE = 'MAILING ZIP CODE',
  MARITAL_STATUS = 'MARITAL STATUS',
  OTHER_NAME = 'OTHER NAME',
  OWNER = 'OWNER_(*)',
  POLICY_STATUS = 'POLICY STATUS',
  SOURCE = 'SOURCE_(*)',
  STATE_PROVINCE = 'STATE/PROVINCE',
  SUB_SOURCE = 'SUB SOURCE_(*)',
  USA_CITIZEN = 'USA CITIZEN',
  WORK_PHONE = 'WORK PHONE',
  ZIP_CODE = 'ZIP CODE',
  SSN_TAX_ID = 'SSN/TAX ID'
}
